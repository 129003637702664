// src/components/Templates.js
import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar, Toolbar, Typography, Button, Container, Paper, List, ListItem,
  ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box
} from '@mui/material';
import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import yaml from 'js-yaml';

function Templates() {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({ name: '', compose: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editorErrors, setEditorErrors] = useState([]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  // Fetch the list of templates
  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/v1/config/list');
      setTemplates(response.data);
    } catch (error) {
      console.error('Ошибка при получении шаблонов:', error);
    }
  };

  // Open the dialog for adding or editing a template
  const handleOpenDialog = async (name) => {
    if (name) {
      setIsEditing(true);
      try {
        const response = await axios.get('/api/v1/config/get', {
          params: { name },
        });
        const yamlData = yaml.dump(response.data.compose);
        setCurrentTemplate({ name: response.data.name, compose: yamlData });
      } catch (error) {
        console.error('Ошибка при получении шаблона:', error);
      }
    } else {
      setIsEditing(false);
      setCurrentTemplate({ name: '', compose: '' });
    }
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentTemplate({ name: '', compose: '' });
    setEditorErrors([]);
  };

  // Save the template (add or update)
  const handleSave = async () => {
    try {
      const jsonData = yaml.load(currentTemplate.compose);
      await axios.post('/api/v1/config/insert', {
        name: currentTemplate.name,
        compose: jsonData,
      });
      fetchTemplates();
      handleCloseDialog();
    } catch (error) {
      console.error('Ошибка при сохранении шаблона:', error);
      alert('Ошибка валидации YAML');
    }
  };

  // Logout function
  const handleLogout = () => {
    setAuth({ isAuthenticated: false });
    navigate('/login');
  };

  // Validate YAML input
  const handleEditorChange = (value) => {
    setCurrentTemplate({ ...currentTemplate, compose: value });

    try {
      yaml.load(value);
      setEditorErrors([]);
    } catch (err) {
      setEditorErrors([err]);
    }
  };

  return (
    <>
      {/* AppBar with navigation */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Управление Шаблонами
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>
            Терминалы
          </Button>
          <Button color="inherit" onClick={() => navigate('/templates')}>
            Шаблоны
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Выйти
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <Container sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()} sx={{ mb: 2 }}>
          Добавить Шаблон
        </Button>
        <Paper>
          <List>
            {templates.map((template) => (
              <ListItem key={template} divider>
                <ListItemText primary={template} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenDialog(template)}
                  sx={{ mr: 1 }}
                >
                  Редактировать
                </Button>
                {/* Remove the delete button as per requirements */}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Container>

      {/* Dialog for adding/editing templates */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>{isEditing ? 'Редактировать Шаблон' : 'Новый Шаблон'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Название Шаблона"
            fullWidth
            value={currentTemplate.name}
            onChange={(e) =>
              setCurrentTemplate({ ...currentTemplate, name: e.target.value })
            }
            disabled={isEditing}
            margin="dense"
          />
          <TextField
            label="Содержимое"
            fullWidth
            multiline
            minRows={10}
            value={currentTemplate.compose}
            onChange={(e) => handleEditorChange(e.target.value)}
            margin="dense"
          />
          {editorErrors.length > 0 && (
            <Typography color="error" sx={{ mt: 1 }}>
              Ошибка в YAML: {editorErrors[0].message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Отмена</Button>
          <Button onClick={handleSave} color="primary" disabled={editorErrors.length > 0}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Templates;
