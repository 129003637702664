// src/components/Login.js
import React, { useState, useContext } from 'react';
import {
  Container, TextField, Button, Typography, Box, Paper
} from '@mui/material';
import { AuthContext } from '../App';

function Login() {
  const { setAuth } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const handleLogin = () => {
    // Simple authentication check (replace with real authentication logic)
    if (credentials.username === 'admin' && credentials.password === 'password') {
      setAuth({ isAuthenticated: true });
    } else {
      alert('Неверный логин или пароль');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Вход в систему
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Логин"
            fullWidth
            margin="normal"
            value={credentials.username}
            onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
          />
          <TextField
            label="Пароль"
            type="password"
            fullWidth
            margin="normal"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
            Войти
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
