// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Login from './components/Login';
import Terminals from './components/Terminals';
import Templates from './components/Templates';

// Create a theme for MUI
const theme = createTheme();

// Create a context for authentication
export const AuthContext = React.createContext();

function App() {
  // Simple authentication state
  const [auth, setAuth] = useState({ isAuthenticated: false });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={auth.isAuthenticated ? <MainApp /> : <Login />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

// Main application after login
function MainApp() {
  return (
    <Routes>
      <Route path="/" element={<Terminals />} />
      <Route path="/templates" element={<Templates />} />
    </Routes>
  );
}

export default App;
