// src/components/Terminals.js
import React, { useEffect, useState, useContext } from 'react';
import {
  AppBar, Toolbar, Typography, Button, Container, Paper, Table, TableHead, TableBody,
  TableRow, TableCell, Select, MenuItem, IconButton, Dialog, DialogTitle,
  DialogContent, DialogActions, Chip, Box, Link
} from '@mui/material';

// Import Font Awesome components and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPowerOff, faSyncAlt, faMoon, faThermometerHalf
} from '@fortawesome/free-solid-svg-icons';

import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Terminals() {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [terminals, setTerminals] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [taskResult, setTaskResult] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);

  useEffect(() => {
    fetchTerminals();
    fetchTemplates();
  }, []);

  // Fetch the list of terminals
  const fetchTerminals = async () => {
    try {
      const response = await axios.get('/api/v1/terminal/list');
      const terminalsList = response.data;

      const terminalsData = await Promise.all(
        terminalsList.map(async (terminalId) => {
          const [configName, onlineStatus] = await Promise.all([
            fetchTerminalConfig(terminalId),
            fetchTerminalStatus(terminalId),
          ]);

          return {
            terminalId,
            configName,
            online: onlineStatus,
          };
        })
      );

      setTerminals(terminalsData);
    } catch (error) {
      console.error('Ошибка при получении терминалов:', error);
    }
  };

  // Fetch the current template of a terminal
  const fetchTerminalConfig = async (terminalId) => {
    try {
      const configResponse = await axios.get('/api/v1/terminal/current', {
        params: { terminal_id: terminalId },
      });
      return configResponse.data.config_name || 'default';
    } catch (error) {
      return 'default';
    }
  };

  // Check if the terminal is online
  const fetchTerminalStatus = async (terminalId) => {
    try {
      const statusResponse = await axios.get('control-service/agent/status', {
        params: { agent_id: terminalId },
      });
      return statusResponse.data.online;
    } catch (error) {
      console.error('Ошибка при получении статуса терминала:', error);
      return false;
    }
  };

  // Fetch the list of templates
  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/v1/config/list');
      setTemplates(response.data);
    } catch (error) {
      console.error('Ошибка при получении шаблонов:', error);
    }
  };

  // Handle template change
  const handleTemplateChange = async (terminalId, newTemplate) => {
    try {
      await axios.post('/api/v1/terminal/config/set', {
        terminal_id: terminalId,
        name: newTemplate,
      });
      setTerminals((prevTerminals) =>
        prevTerminals.map((terminal) =>
          terminal.terminalId === terminalId
            ? { ...terminal, configName: newTemplate }
            : terminal
        )
      );
    } catch (error) {
      console.error('Ошибка при изменении шаблона терминала:', error);
    }
  };

  // Execute a task on the terminal
  const executeTask = async (terminalId, taskName) => {
    try {
      // Check if terminal is online
      const terminal = terminals.find(t => t.terminalId === terminalId);
      if (!terminal.online) {
        alert('Терминал офлайн');
        return;
      }

      const response = await axios.post('control-service/task', {
        agent_id: terminalId,
        task_name: taskName,
      });

      const result = response.data;

      // Parse the response if it's a JSON string
      let parsedResponse = result.response;
      try {
        parsedResponse = JSON.parse(result.response);
        if (parsedResponse.response) {
          parsedResponse = parsedResponse.response;
        }
      } catch (e) {
        // Leave as is if parsing fails
      }

      setTaskResult({
        terminalId,
        taskName,
        status: result.status,
        response: parsedResponse,
      });
      setOpenTaskDialog(true);
    } catch (error) {
      console.error('Ошибка при выполнении задачи:', error);
      setTaskResult({
        terminalId,
        taskName,
        status: 'error',
        response: error.message,
      });
      setOpenTaskDialog(true);
    }
  };

  // Logout function
  const handleLogout = () => {
    setAuth({ isAuthenticated: false });
    navigate('/login');
  };

  // Render the task result dialog
  const renderTaskResult = () => {
    if (!taskResult) return null;

    const { response } = taskResult;

    // If the response contains a URL, display it as a link
    if (typeof response === 'object' && response.url) {
      return (
        <Typography>
          <strong>Ответ:</strong>{' '}
          <Link href={response.url} target="_blank" rel="noopener">
            {response.url}
          </Link>
        </Typography>
      );
    }

    // Otherwise, display the response as text
    return (
      <Typography>
        <strong>Ответ:</strong> {JSON.stringify(response)}
      </Typography>
    );
  };

  return (
    <>
      {/* AppBar with navigation */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Управление Терминалами
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>
            Терминалы
          </Button>
          <Button color="inherit" onClick={() => navigate('/templates')}>
            Шаблоны
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Выйти
          </Button>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <Container sx={{ mt: 4 }}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID Терминала</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Шаблон</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {terminals.map((terminal) => (
                <TableRow key={terminal.terminalId}>
                  <TableCell>{terminal.terminalId}</TableCell>
                  <TableCell>{/* Terminal name can be added here */}</TableCell>
                  <TableCell>
                    <Select
                      value={terminal.configName}
                      onChange={(e) => handleTemplateChange(terminal.terminalId, e.target.value)}
                      disabled={!terminal.online}
                    >
                      {templates.map((template) => (
                        <MenuItem key={template} value={template}>
                          {template}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    {terminal.online ? (
                      <Chip label="Online" color="success" />
                    ) : (
                      <Chip label="Offline" color="default" />
                    )}
                  </TableCell>
                  <TableCell>
                    {/* Control buttons with Font Awesome icons */}
                    <IconButton
                      color="primary"
                      onClick={() => executeTask(terminal.terminalId, 'shutdown_system')}
                      disabled={!terminal.online}
                      title="Выключить"
                    >
                      <FontAwesomeIcon icon={faPowerOff} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => executeTask(terminal.terminalId, 'reboot_system')}
                      disabled={!terminal.online}
                      title="Перезагрузить"
                    >
                      <FontAwesomeIcon icon={faSyncAlt} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => executeTask(terminal.terminalId, 'hibernate_system')}
                      disabled={!terminal.online}
                      title="Гибернация"
                    >
                      <FontAwesomeIcon icon={faMoon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => executeTask(terminal.terminalId, 'get_heatmap')}
                      disabled={!terminal.online}
                      title="Получить тепловую карту"
                    >
                      <FontAwesomeIcon icon={faThermometerHalf} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>

      {/* Dialog to display task results */}
      <Dialog open={openTaskDialog} onClose={() => setOpenTaskDialog(false)}>
        <DialogTitle>Результат выполнения задачи</DialogTitle>
        <DialogContent>
          {taskResult && (
            <Box>
              <Typography>
                <strong>Терминал ID:</strong> {taskResult.terminalId}
              </Typography>
              <Typography>
                <strong>Задача:</strong> {taskResult.taskName}
              </Typography>
              <Typography>
                <strong>Статус:</strong> {taskResult.status}
              </Typography>
              {renderTaskResult()}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTaskDialog(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Terminals;
